import { request } from '@/utils/request.js'

// 获取企业关联账号列表
export function getLink(id, params) {
    return request.get(`/company/${id}/links`, { params })
}

// 用户关联企业
export function link(id) {
    return request.post(`/company/${id}/links`)
}

// 企业解除关联
export function unlink(company, user) {
    return request.delete(`/company/${company}/links/${user}`)
}

// 企业确认关联
export function confirmLink(company, user, data) {
    return request.post(`/company/${company}/links/${user}`, data)
}
